#accessPermission .DLfontSize1{
    /* font-size: 12 px !important; */
    margin: 10px 0 -2px;
}

#accessPermission .DLMargin{
    margin-top: 0px;
    margin-bottom: -15px !important;
}

@media (max-width: 768px) { 
	#accessPermission .DLMargin {
		margin-top: 0px;
        margin-bottom: 0px !important;
	}
}