#coverageSummary
  .gwp-portlet-container
  .panel
  .dataTables_wrapper
  .row:nth-child(3) {
  display: block !important;
  padding-left: 40px;
  padding-right: 40px;
}

#coverageSummary .gwp-details-control,
.ext-info-id {
  /*
	*/
  width: 19px !important;
  min-width: 19px !important;
  max-width: 19px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
}

#coverageSummary .ext-coverage-info dd {
  padding-top: 0px !important;
}

#coverageSummary .dtr-details dd {
  padding-top: 10px;
}

#coverageSummary #memInfo dt {
  text-align: left;
  padding-top: 10px;
  padding-right: 6px;
  margin-bottom: 0px;
  width: auto;
  white-space: break-spaces;
  overflow-wrap: break-word ;
  text-overflow: clip;
}

#coverageSummary #memInfo dd {
  padding-top: 10px;
  margin-left: 0px;
}

#coverageSummary .coverage-title-message {
  float: left !important;
  font-size: 12px;
  font-weight: normal;
}

#coverageSummary .coverage-summary-title-message {
  float: left !important;
  font-size: 12px;
  margin-right: 0px;
  margin-bottom: 5px;
  margin-top: 5px;
}

#coverageSummary .ext-coverage-info {
  margin-left: 15px;
  margin-right: 15px;
}

#coverageSummary .ext-coverage-info .ext-agent-beneficiaries {
  padding-left: 0px;
  padding-right: 0px;
}

#coverageSummary .empty-dd {
  min-height: 0px !important;
}

#coverageSummary .extInfo {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

#coverageSummary .extInfo [class^="col-"] {
  padding: 0px;
}

#coverageSummary #asOfDateSubmit {
  margin-top: 10px;
}

#coverageSummary .marginBtn {
  margin-top: 10px;
  margin-bottom: 10px;
}

#coverageSummary .dtr-details {
  margin-bottom: 0px;
  width: 100%;
}

#coverageSummary .dtr-details .gwp-dl {
  margin-bottom: 0px;
}

#coverageSummary .detail label {
  margin-top: 0px;
  margin-bottom: 0px;
}

#coverageSummary .dep-cov-hr {
  margin-top: 5px;
  margin-bottom: 5px;
}

#coverageSummary table.dataTable {
  margin-top: 0px !important;
}

#coverageSummary .modal-body dd {
  min-height: 17px;
  padding-top: 0px; 
}


#coverageSummary .extInfo dd {
  min-height: 17px;
  word-break: break-all;
}


#coverageSummary .borderless {
  border: none;
  box-shadow: none;
}

#coverageSummary .td-border-less {
  border: none !important;
}

#coverageSummary .panel-body .list {
  padding-left: 0px;
  margin-bottom: 0px;
}

#coverageSummary #agentInfo dt {
  text-align: left;
  margin-bottom: 0px;
  margin-top: 0px;
  width: 20%;
}

#coverageSummary #agentInfo dd {
  margin-left: 0px;
  width: 80%;
}

#coverageSummary #agentInfo .agentName {
  color: #222222 !important;
  width: 100%;
}

#coverageSummary #agentInfo {
  margin-bottom: 5px;
}

#coverageSummary .detail dt {
  padding-left: 0px;
  padding-right: 0px;
}

#coverageSummary .coverage-container .panel {
  margin-bottom: 5px;
}

#coverageSummary .coverage-container .panel .panel-heading {
  padding: 0px 10px;
  line-height: 30px;
}

#coverageSummary .coverage-container .panel .panel-body {
  padding: 6px;
}

#coverageSummary .coverage-summary-title {
  text-align: left;
  margin-bottom: 5px;
  margin-right: 46px;
  margin-top: 5px;
  float: left;
}

#coverageSummary .coverage-detail-group {
  padding-top: 10px;
}

#coverageSummary .coverage-summary-header {
  margin-top: 15px;
  margin-bottom: 15px;
}

#coverageSummary .gwp-table {
  margin-top: 0px;
}

#coverageSummary .coverage-panel-body .dataTable {
  width: 100%;
  border: none !important;
}

#coverageSummary .coverage-panel-body table th {
  border: none !important;
  padding-right: 8px;
}

#coverageSummary .coverage-panel-body table td {
  border: none !important; 
  padding-right:8px !important;

}
#coverageSummary .coverage-panel-body1 table td {
  border: none !important;
  background-color: white;

}

#coverageSummary .coverage-panel-header {
  font-weight: 600;
}

#coverageSummary .coverage-panel-body-title {
  background: #e0f0d0;
  line-height: 30px;
}

#coverageSummary .coverage-panel-body-content {
  line-height: 30px;
}

#coverageSummary .coverage-panel-body-content2 {
  line-height: 30px;
  background: #edebdb;
}

#coverageSummary .coverage-title {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 5px;
}

#coverageSummary .coverage-info-title {
  font-weight: bold;
  font-size: 16px;
}

#coverageSummary .form_date {
  font-weight: normal;
}

#coverageSummary .coverage-bl-font-black {
  color: black !important;
}

#coverageSummary .text-right {
  text-align: right;
}

#coverageSummary .text-bold {
  font-weight: bold;
}

#coverageSummary th.amt-right {
  text-align: right;
}

#coverageSummary th.limit-right {
  text-align: right;
}

#coverageSummary td.amt-right {
  text-align: right;
  padding-right: 50px !important;
}

#coverageSummary td.limit-right {
  text-align: right;
  padding-right: 10px !important;
}

@media (min-width: 451px) {
  #coverageSummary .coverage-summary-title-message {
    float: right !important;
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: 5px;
    margin-right: 46px;
  }
  #coverageSummary .subTableMobile {
    display: none;
  }
}

@media (max-width: 450px) {
  #coverageSummary .subTable{
    display: none;
}
#coverageSummary .extInfo {
  width:90%;
}
}
@media (min-width: 992px) {
  #coverageSummary #asOfDateSubmit {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  #coverageSummary .cs-benefitLimitOthLmt {
    display: none;
  }
  #coverageSummary .coverage-summary-header {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  #coverageSummary .benefitMsg {
    margin-top: 10px;
  }

  #coverageSummary .dl-horizontal.gwp-dl > dt {
    width: 90% !important;
  }
  #coverageSummary .dl-horizontal.gwp-dl > dd {
    width: 90% !important;
  }
  #coverageSummary .dtr-details dt{
    width: 90% !important;
  }
  #coverageSummary .dtr-details dd{
    width: 90% !important;
  }
  #coverageSummary .noBorder table{
    width: 90% !important;
  }
  
  #coverageSummary #showBenefitLimitTDM .dtr-details dd{
    padding-left: 10px ;
  }
  #coverageSummary #showBenefitLimitTDM .dtr-details dt{
    padding-left: 10px ;
  }
  
  #coverageSummary hr{
    margin-top: 10px;
    margin-bottom: 10px;
  } 

}
@media (width: 768px) {
  #coverageSummary .dl-horizontal.gwp-dl > dt {
    margin-left: 0px !important;
  }
  #coverageSummary .dl-horizontal.gwp-dl > dd {
    margin-left: 0px !important;
  }
  
}

@media (min-width: 768px) {
  /*
  #coverageSummary .dl-horizontal.gwp-dl > dt {
    width: 30% !important;
  }

  #coverageSummary .dl-horizontal.gwp-dl > dd {
    width: 70% !important;
  }
 */
 #coverageSummary #memInfo dt {
  width: 160px !important;
}
  #coverageSummary .dl-horizontal.gwp-dl.marginLeft180px > dd{ 
    margin-left: 180px !important;
  }
  #coverageSummary .list .agentInfo dt {
    text-align: left;
    margin-bottom: 0px;
    width: 20%;
  }
  #coverageSummary .marginBtn {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  #coverageSummary .extInfo {
    margin-left: 20px !important;
  }
  #coverageSummary .extInfo [class^="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
  #coverageSummary .empty-dd {
    min-height: 17px !important;
  }
  #coverageSummary .ext-coverage-info {
    margin-left: 35px;
  }
  #coverageSummary .ext-coverage-info .ext-agent-beneficiaries {
    padding-left: 40px;
    padding-right: 40px;
  }
  #coverageSummary .coverage-title-message {
    float: right !important;
    font-size: 12px;
    text-align: right;
    font-weight: normal;
  }
  
  #coverageSummary .dl-horizontal.gwp-dl.width95P > dd{ 
    margin-left: 95%;
  }
  #coverageSummary .dl-horizontal.gwp-dl > dd{ 
    margin-left: 60px;
  }
  
  #coverageSummary .dl-horizontal.gwp-dl.width95P > dt{ 
    margin-left: 95%;
  }
  #coverageSummary .planDetail>dd {
    margin-left: 300px; 
    text-align: left;
  }
  
  #coverageSummary .planDetail>dt {
    width: 300px !important; 
    text-align: left;
  }
}

#coverageSummary .dl-horizontal.gwp-dl.breakWord > dd{ 
  word-break: break-all;
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

#coverageSummary .dl-horizontal.gwp-dl > dt{  
  /*margin-top: 2px !important;*/
  margin-bottom: 2px !important;
  width: 60px;
  text-align: left;
}


#coverageSummary .pdcss {
  width: 110px !important;
}

#coverageSummary .form-inline .gwp-label {
  margin-right: 5px;
}

#coverageSummary .form-inline .rw-datetime-picker {
  margin-right: 5px;
  width: 180px;
  display: inline-table;
  vertical-align: middle;
  font-size: 12px !important;
  font-weight: normal !important;

}

#coverageSummary .floatRight{
  float: right !important;
}
#coverageSummary .floatLeft{
  float: left !important;
}
#coverageSummary .textRight{
  text-align: right !important;
}
#coverageSummary .textLeft{
  text-align: left !important;
}

#coverageSummary .marginTop5px {
  margin-top: 5px !important;
}

#coverageSummary .dataTables_info {
  display: none;
}
#coverageSummary .backgroundColorWhite {
  background-color: #fff !important;
  border: none !important;
}

#coverageSummary .backgroundColorGray {
  background-color: #f9f9f9;
  border: none;
}
#coverageSummary .noBorder {
  border:none;
}

#coverageSummary .gwp-btn-container {
  margin-top: 0px;
}

#coverageSummary .cs-div{
  float: right;
    width: 50%;
    padding-right: 10px;
}
#coverageSummary .cs-left-div{
  float: left;
    width: 50%;
}




#coverageSummary .scroll650px{
  overflow-y: scroll;
  height: 650px;
}



#coverageSummary .agentEmail{
  margin-left: 0px !important;
}

#coverageSummary .paddingLeft10px{
  padding-left: 10px !important;
}
#coverageSummary .paddingLeft0px{
  padding-left: 0px !important;
}
#coverageSummary .paddingTop0px{
  padding-top: 0px !important;
}
#coverageSummary .subTableSubTitle{
  padding-left: 0px !important;
  width: 95%
}
#coverageSummary .subTableSubField{
  padding-left: 10px !important;
  width: 40%;
}
#coverageSummary .noPadding{
  padding: 0px !important;
}

#coverageSummary .coverage-panel-body{
  padding: 0px !important;
}


#coverageSummary .gwp-portlet-container .dataTables_wrapper table.dataTable .dtr-details tr{
  border-bottom: none !important;
}

#coverageSummary .container table {
 border: none !important;
}

#coverageSummary .font12px300px{
  font-size: 12px;
  font-weight: 300;
 }
 #coverageSummary .fontBold {
  font-weight: bold;
}

#coverageSummary .benefitMsg {
  margin-bottom: 10px;
}
#coverageSummary .width95P {
  width: 95%;
}

#coverageSummary .dl-horizontal> dt {
  text-align: left;
}

#coverageSummary .paddingLeft15px{
  padding-left: 15px;
}
#coverageSummary .paddingLeft0px{
  padding-left: 0px;
}
#coverageSummary .marginTop10px{
  margin-top: 10px;
}
#coverageSummary .fontBlue{
  color: #009ace !important;
}
#csblDialog .form-group .gwp-label {
  color: #337ab7!important;
  width: 170px;
}

#coverageSummary .displayNone {
  display: none;
}

#coverageSummary .bizTel {
  margin-left: 0px; 
}

@media print
{
.planDetail>dt {
  text-align: left !important;
}

.planDetail {
  margin-bottom: 10px !important;
}

}

#coverageSummary .planDetail {
  margin-bottom: 10px;
}

#coverageSummary .printButton {
  border: 0;
  background-color: transparent;
  outline: none;
  color: #009ace;
  font-weight: bold;
  height: 31px;
  cursor: pointer;
  font-size: 12px!important;
}