/* Login.css */

.login .login-panel-body{
	background-color:#e1e6e9;
	max-width:315px;
	/* height:165px; */
	margin:auto;
	padding: 15px 0 0 0;
}

.login .login-panel-content{
	margin: 0 18px;
}

.login .login-panel-body2{
	background-color:#e1e6e9;
	max-width:420px;
	height:345px;
	margin:0 auto;
}

.login .login-panel-body3{
	background-color:#e1e6e9;
	max-width:315px;
	height:235px;
	margin:auto;
	padding: 15px 0 0 0;
}

.login .login-page-title{
	max-width:315px;
	margin:calc(5% + 40px) auto 10px;
}

.login .login-page-title2{
	max-width:420px;
	margin:calc(2% + 40px) auto 10px;
}

.login .userID-label{
	margin-top:15px;
}

.login .security-caption {
	font-family:Arial;
	font-size:12px;
	color:#454545;
	margin-top:25px;
	font-weight:bold;
}

.login .login-panel-content button {
	margin-top:10px;
	margin-bottom: 15px;
}

.login .gwp-label-ro {
	font-weight:bold;
}

.login .btn[id=login]{
    margin-left:5px;
}

.login .signUpText{
	margin-top:10px;
}

/* .login .signUpText > a:not(:last-child):after{
	content:"  |  ";
} */
.login .loginDirectlyBtn {
	margin-left: 0px;
	/* margin-bottom: 15px; */
}
.login .nextBtn {
	margin-left: 0px;
}
.login .previousBtn {
	margin-left: 0px;
}
.login .loginBtn {
	margin-left: 0px;
}
@media (max-width: 330px){
	#login .linkWrap{
		display: block;
	}
	#login .login-panel-body{
		background-color:#e1e6e9;
		max-width:315px;
		height:195px;
		margin:auto;
		padding: 15px 0 0 0;
	}
}
#login .gwp-bold{
	font-weight: 700;
	display: inline-block;
}

@media (max-width: 767px){
	#login .max-portal-content{
		word-wrap: break-word;
		display: block;
	}
	#login .min-portal-content{
		display: none;
	}
}
@media (min-width: 768px){
	#login .max-portal-content{
		display: none;
	}
	#login .min-portal-content{
		word-wrap: break-word;
		width: 100%;
		float:left;
		display: block;
	}
}