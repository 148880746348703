#userinquiry .compWidth {
    width: 40%;
}

#userinquiry .information {
    margin-right: 22px !important;
}

#userinquiry textarea.form-control {
    height: 100px !important;
}

#userinquiry .followActionClosed {
    word-wrap: break-word;
    max-width: 240px !important;
}

.bold{font-weight:bold;}