.file-group {
    position: relative;
    text-align: center;
    margin-top: 15px;
}

.file-group .hidden-type {
    display: none;
}

.attachment-placeholder {
    min-height: 40px;
}

.attachment-placeholder img {
    width: 40px;
}

.download-placeholder img {
    width: 16px;
}

.attachment-list {
    display: flex;
    cursor: pointer;
    align-items: center;
    margin-top: 10px;
}

.attachment-list span {
    max-width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 5px;
}
.attachment-list:hover span:nth-child(1) {
    color: red;
    font-weight: 900;
}

.text-wrap {
    white-space: normal;
    word-wrap: break-word;
}

.loader_submit_form{
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    background: #888888cc;
    z-index: 9;
}
.loader_submit_form span{
    position: relative;
    top: 50%;
    font-size: 25px;
    color: #fff;
}