#firstLogUpdate .orange {
  font-size: 14 px;
  color: orange;
}
#firstLogUpdate .gwp-btn-container {
  margin-top: 1px !important;
}

#firstLogUpdate .pfoeclass1 {
  /* border:1px solid #BFBFBF; */
  padding: 12px;
  margin-bottom: 0;
}
#firstLogUpdate .gwp-page-title {
  font-weight: 600;
  font-size: 20px;
  margin: 0;
  margin-left: 0;
}

#firstLogUpdate .red {
  font-size: 12 px;
  color: red;
}
#firstLogUpdate > .row > div > .gwp-page-title {
  margin-bottom: 10px;
}
#firstLogUpdate .gwp-information {
  margin-bottom: 15px;
}
#firstLogUpdate .panel-body {
  padding: 15px 12px 15px 12px;
  /* border:1px solid #BFBFBF; */
}
#firstLogUpdate .req-msg {
  margin-right: 3px;
}
#firstLogUpdate .blankup {
  margin-top: 8px;
}
#firstLogUpdate .blankdown {
  margin-top: 10px;
}
#firstLogUpdate .form-group1 {
  margin-bottom: 8px;
  margin-top: 6px;
  font-weight: 600;
}
#firstLogUpdate .marginbottom10px {
  margin-bottom: 10px !important;
}
#firstLogUpdate .glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: "Glyphicons Halflings";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  margin-right: 3px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#firstLogUpdate .warning {
  font-size: 14px;
}
#firstLogUpdate .warning-message {
  margin-top: 10px;
  margin-left: 15px;
}
@media (max-width: 400px) {
  #firstLogUpdate .btn-viewAll {
    margin-left: 0;
  }

  #firstLogUpdate .aptclass {
    border: 1px solid #bfbfbf;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
